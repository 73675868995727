<template>
    <div>
        <div class="modal fade" id="technicalSupportRequestModal" tabindex="-1" aria-labelledby="technicalSupportRequestModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="technicalSupportRequestModalLabel" class="font-weight-bold">{{ModalTitle}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Kode TS Request</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="RequestCode" v-model="RequestCode" class="font-weight-bold"/>
                                    <label id="errorRequestCode" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Tanggal Request</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <date-picker
                                        v-model="RequestDate"
                                        format="DD MMM YYYY"
                                        type="date"
                                        placeholder="Select date"
                                        value-type="YYYY-MM-DD"
                                        style="width:100%"
                                    ></date-picker>
                                    <label id="errorRequestDate" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow class="pt-3">
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Project</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <v-select id="Project" class="pb-3" :options="ProjectData" v-model="Project" @input="onChangeProject()" />
                                    <label id="errorProject" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Salesman</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="SalesmanName" v-model="SalesmanName" class="font-weight-bold" readonly/>
                                    <label id="errorSalesmanName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Alamat</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <textarea id="Address" class="form-control" rows="3" v-model="Address"></textarea>
                                    <label id="errorAddress" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow class="pt-3">
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Note</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <textarea id="Note" class="form-control" rows="3" v-model="Note"></textarea>
                                    <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <div class="p-3 border rounded" style="width: 100%;">
                                    <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Visitor</h4></label>
                                    <hr>
                                    <datasource ref="visitorDataSource" :data="this.VisitorGridData" :page-size="10" :schema-model-fields="this.VisitorSchemaModel"/>

                                    <kendo-grid ref="gridVisitor"
                                            :data-source-ref="'visitorDataSource'"
                                            :pageable="true"
                                            :editable="true"
                                            :scrollable="true"
                                            :toolbar="['create']"
                                            :cellClose="cellClose"
                                            :resizable="true">

                                        <kendo-grid-column  :field="'visitor_name'"
                                                            :title="'Nama'"
                                                            :width="200"
                                                            :editor= "visitorDropDownEditor"
                                                            :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                    </kendo-grid>
                                </div>
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { response } from '../../../../infrastructure/constant/response';
import technicalSupportRequestServices from '../Script/TechnicalSupportRequestScript';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';

export default {
    name: 'TechnicalSupportRequestForm',
    props: ['reload'],
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    data: function () {
        return {
            ModalTitle : '',
            Id: '',

            RequestCode: '',
            RequestDate: '',
            StatusData: [],
            Status: '',
            ProjectData: [],
            Project: '',
            SalesmanId: '',
            SalesmanName: '',
            Address: '',
            Note: '',
            VisitorData: [],

            //grid
            VisitorGridData: [],
            VisitorSchemaModel: {
                visitor_name: { type: "string", editable: true },
                visitor_id: { type: "number", editable: false },
            },

            Error: 0,
            SaveType: '',
        }
    },
    // mounted(){

    // },
    methods: {
        async addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Add Technical Support Request Project: ';   

            this.RequestCode = '';
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.RequestDate = dateNow;
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';
            this.ProjectData = await technicalSupportRequestServices.getProjectDropdown();
            this.Project = '';
            this.SalesmanId = '';
            this.SalesmanName = '';
            this.Address = '';
            this.Note = '';
            this.VisitorData = await technicalSupportRequestServices.getVisitorDropdown();

            //grid  
            this.VisitorGridData = [];

            this.SaveType = 'Add';
            window.$('#technicalSupportRequestModal').modal('show');
        },
        async editClick(data, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Edit Technical Support Request Project: '       

            this.RequestCode = data.ts_request_name;
            this.Id = data.ts_request_id;
            this.RequestDate = data.ts_request_date;
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_ts_request');
            this.Status = data.status;
            this.ProjectData = await technicalSupportRequestServices.getProjectDropdown();
            this.Project = this.ProjectData.find(c => c.value == data.project_id);
            this.SalesmanId = data.sales_id;
            this.SalesmanName = data.sales_name;
            this.Address = data.address;
            this.Note = data.notes;
            this.VisitorData = await technicalSupportRequestServices.getVisitorDropdown();

            //grid  
            this.VisitorGridData = this.$globalfunc.objectToArrayConverter(data.ts_request_visitor, 'TechnicalSupport-TechnicalSupportRequest-VisitorGrid');

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Technical Support Request Project: ';
            }

            window.$('#technicalSupportRequestModal').modal('show');
        },
        async onChangeProject(){
            if(this.Project == null || this.Project == ''){
                this.SalesmanId = '';
                this.SalesmanName = '';
            }else{
                this.SalesmanId = this.Project.sales_id;
                this.SalesmanName = this.Project.sales_name;
            }
        },
        cellClose(e){
            var grid = this.$refs.gridVisitor.kendoWidget();
            var dataItem = e.sender.dataItem($(e.container).parent());

            var duplicate = this.VisitorGridData.find(c => c.visitor_id == dataItem.visitor_name);
            if(duplicate == undefined){
                var visitorData = this.VisitorData.find(c => c.value == dataItem.visitor_name);
                dataItem.visitor_name = visitorData.label;
                dataItem.visitor_id = visitorData.value;

                this.VisitorGridData = this.$globalfunc.objectToArrayConverter(grid.dataSource._data, 'TechnicalSupport-TechnicalSupportRequest-VisitorGrid');
            }else{
                dataItem.visitor_name = null;
                dataItem.visitor_id = null;
                this.$swal("Error", "Visitor tidak boleh sama", "error");
                
                var data = this.$globalfunc.objectToArrayConverter(grid.dataSource._data, 'TechnicalSupport-TechnicalSupportRequest-VisitorGrid');
                this.VisitorGridData = data.filter(function( obj ) {
                                return obj.visitor_id !== null;
                            });
            }
        },
        async visitorDropDownEditor(container, options) {
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.VisitorData
                    }
                });
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.RequestCode == '' || this.RequestCode == null){
                this.errorShow('errorRequestCode');
            }
            if(this.RequestDate == '' || this.RequestDate == null){
                this.errorShow('errorRequestDate');
            }
            if(this.Project == '' || this.Project == null){
                this.errorShow('errorProject');
            }
            if(this.SalesmanName == '' || this.SalesmanName == null){
                this.errorShow('errorSalesmanName');
            }
            if(this.RequestDate == '' || this.RequestDate == null){
                this.errorShow('errorRequestDate');
            }
            if(this.Address == '' || this.Address == null){
                this.errorShow('errorAddress');
            }
            if(this.VisitorGridData.length < 1){
                this.$swal("Error", "Visitor masuk harus diisi terlebih dahulu", "error");
                this.Error++;
            }
        },
        saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){
                //Add
                var gridData = this.$globalfunc.objectToArrayConverter(this.VisitorGridData, 'TechnicalSupport-TechnicalSupportRequest-VisitorGrid');
                const technicalSupportRequestData = {
                    ts_request_date: this.RequestDate,
                    ts_request_name: this.RequestCode,
                    status: this.Status,
                    sales_id: parseInt(this.SalesmanId),
                    project_id: parseInt(this.Project.value),
                    address: this.Address,
                    notes: this.Note,
                    ts_request_visitor: gridData
                };
                
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : technicalSupportRequestData
                    }
                    
                    technicalSupportRequestServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#technicalSupportRequestModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    });
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : technicalSupportRequestData
                    }

                    technicalSupportRequestServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#technicalSupportRequestModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    });
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>

<style scoped>

</style>
