<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Technical Support Complain : {{TSComplainId}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Kode TS Komplain</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ComplainCode" v-model="ComplainCode" class="font-weight-bold"/>
                            <label id="errorComplainCode" class="form-error" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Komplain</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ComplainDate" v-model="ComplainDate" class="font-weight-bold" readonly/>
                            <label id="errorComplainDate" class="form-error" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Kunjungan Komplain</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="ComplainVisitDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorComplainVisitDate" class="form-error" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow>
                        
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode TS Visit</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="TSVisitCode" v-model="TSVisitCode" class="font-weight-bold" readonly/>
                            <label id="errorTSVisitCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Visitor</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="Visitor" v-model="Visitor" class="font-weight-bold" readonly/>
                            <label id="errorVisitor" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Note</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea id="Note" class="form-control" rows="3" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <br>

                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/technical-support-complain')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';
import technicalSupportServices from '../Script/TechnicalSupportComplainScript.js';

export default {
    name: 'TechnicalSupportComplainForm',
    props: ['reload'],
    components: {
        'date-picker' : datePicker,
    },
    data: function () {
        return {
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,

            TSComplainId: '',

            ComplainCode: '',
            ComplainDate: '',
            ComplainVisitDate: '',
            StatusData: [],
            Status: '',

            TSVisitId: '',
            TSVisitCode: '',

            Visitor: '',
            VisitorId: '',
            Note: '',

            Error: 0,
            SaveType: '',
        }
    },
    async mounted(){
        if (this.FormType == 'Add') {
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            
            this.ComplainDate = dateNow;
            this.ComplainVisitDate = dateNow;

            this.StatusData = [ {label:"New", value:"New"} ];
            this.Status = 'New';

            this.TSVisitId = this.Id;
            var TSVisitData = await technicalSupportServices.getTSVisitData(this.TSVisitId);

            this.TSVisitCode = TSVisitData.ts_visit_name;
            this.Visitor = TSVisitData.visitor_name;
            this.VisitorId = TSVisitData.visitor_id;
            this.Note = '';

            this.SaveType = 'Add';
        }
        else {
            $(".form-error").css("display", "none");
            $(".save-button").show();

            var data = await technicalSupportServices.getTSComplainData(this.Id);
            
            this.ComplainCode = data.ts_complain_name;
            this.ComplainDate = kendo.toString(kendo.parseDate(data.ts_complain_date, 'yyyy-MM-dd'), 'yyyy-MM-dd');
            this.ComplainVisitDate = data.ts_complain_visit_date;

            this.StatusData = await globalfunc.globalDropdown('ddl_status_ts_complain');
            this.Status = data.status;

            this.TSVisitId = data.ts_visit_id;
            this.TSVisitCode = data.ts_visit_name;
            this.Visitor = data.visitor_name;
            this.VisitorId = data.visitor_id;
            this.Note = data.notes;

            this.SaveType = 'Edit';
            
            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    methods: {
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if (this.ComplainCode == '' || this.ComplainCode == null) {
                this.errorShow('errorComplainCode');
            }
            if (this.ComplainVisitDate == '' || this.ComplainVisitDate == null) {
                this.errorShow('errorComplainVisitDate');
            }
        },
        saveClick() {
            this.inputValidation();
            
            if (this.Error == 0) {
                //Add
                const tsCompalinData = {
                    ts_complain_date: this.ComplainDate,
                    ts_complain_name: this.ComplainCode,
                    ts_complain_visit_date: this.ComplainVisitDate,
                    status: this.Status,
                    ts_visit_id: this.TSVisitId,
                    visitor_id: this.VisitorId,
                    notes: this.Note,
                };
                
                this.$loading(true);
                if (this.SaveType == 'Add') {

                    const variables = {
                        data : tsCompalinData
                    }

                    technicalSupportServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/technical-support-complain' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : tsCompalinData
                    }

                    technicalSupportServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success").then(
                            this.$router.push({ path: '/technical-support-complain' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>

<style scoped>
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>