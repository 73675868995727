<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Technical Support Visit : {{TSVisitId}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Kode TS Visit</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="VisitCode" v-model="VisitCode" class="font-weight-bold"/>
                            <label id="errorVisitCode" class="form-error" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Visit</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="VisitDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorVisitDate" class="form-error" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Visitor</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Visitor" class="pb-3" :options="VisitorData" v-model="Visitor"/>
                            <label id="errorVisitor" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Note</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea id="Note" class="form-control" rows="3" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Attachment</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <div class="row">
                                <div class="col-8 p-0 pl-3">
                                    <div class="form-control">
                                        <div v-if="this.AttachmentStatus == 'Uploaded'">
                                            <a href="#" @click="attachmentClick()" class="label-file" id="uploadedAttachment"><label>{{Attachment}}</label></a>
                                        </div>
                                        <div v-if="this.AttachmentStatus == 'New'">
                                            <label class="label-file">{{Attachment}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 p-0 px-2">
                                    <label for="Attachment" class="form-control" style="text-align: center;"><i class="fa fa-upload"></i></label>
                                    <input type="file" id="Attachment" @input="onChangeAttachment()" style="display:none"/>
                                </div>
                                <div class="col-2 p-0 pr-3">
                                    <CButton type="button" @click="deleteAttachmentClick()" id="deleteAttachmentButton" color="danger" style="width:100%"> <i class="fa fa-trash"></i> </CButton>
                                </div>
                            </div>
                            <label id="errorAttachment" class="form-error" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <br>
                    
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/technical-support-visit')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';
import technicalSupportServices from '../Script/TechnicalSupportVisitScript.js';

export default {
    name: 'TechnicalSupportVisitForm',
    props: ['reload'],
    components: {
        'date-picker' : datePicker,
    },
    data: function () {
        return {
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,

            TSVisitId: '',
            
            VisitCode: '',
            VisitDate: '',
            StatusData: [],
            Status: '',

            TSRequestId: '',
            VisitorData: [],
            Visitor: '',
            Note: '',

            Attachment: '',
            AttachmentStatus: '',

            Error: 0,
            SaveType: '',
        }
    },
    async mounted(){
        if (this.FormType == 'Add') {
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.VisitDate = dateNow;
            this.StatusData = [ {label:"New", value:"New"} ];
            this.Status = 'New';
            this.TSRequestId = this.Id;
            this.VisitorData = await technicalSupportServices.getVisitorDropdown(this.TSRequestId);
            this.Visitor = '';
            this.Note = '';

            this.SaveType = 'Add';
        }
        else {
            $(".form-error").css("display", "none");
            $(".save-button").show();

            var data = await technicalSupportServices.getTSVisitData(this.Id);
            
            this.VisitCode = data.ts_visit_name;
            this.VisitDate = data.ts_visit_date;
            this.StatusData = await globalfunc.globalDropdown('ddl_status_ts_visit');
            this.Status = data.status;
            this.TSRequestId = data.ts_request_id;
            this.VisitorData = await technicalSupportServices.getVisitorDropdown(this.TSRequestId);
            this.Visitor = this.VisitorData.find(c => c.value == data.visitor_id);
            this.Note = data.notes;
            
            //Attachment
            this.Attachment = data.attachment;
            this.AttachmentStatus = 'Uploaded';

            this.SaveType = 'Edit';
            
            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    methods: {
        onChangeAttachment(){
            var attachment = document.getElementById('Attachment').files[0];
            
            this.AttachmentStatus = 'New';
            this.Attachment = attachment.name;
        },
        attachmentClick(){
            this.$refs.fileViewer.viewClick(this.Attachment);
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");
            
            
            if (this.VisitCode == '' || this.VisitCode == null) {
                this.errorShow('errorVisitCode');
            }
            if (this.VisitDate == '' || this.VisitDate == null) {
                this.errorShow('errorVisitDate');
            }
            if (this.Visitor == '' || this.Visitor == null) {
                this.errorShow('errorVisitor');
            }
        },
        saveClick() {
            this.inputValidation();
            
            var attachment = document.getElementById('Attachment').files[0];
            if (attachment == undefined) (
                attachment = null
            )
            
            if (this.Error == 0) {
                //Add
                const tsVisitData = {
                    ts_visit_date: this.VisitDate,
                    ts_visit_name: this.VisitCode,
                    status: this.Status,
                    ts_request_id: this.TSRequestId,
                    visitor_id: this.Visitor.value,
                    notes: this.Note,
                    attachment: attachment
                };
                
                this.$loading(true);
                if (this.SaveType == 'Add') {

                    const variables = {
                        data : tsVisitData
                    }

                    technicalSupportServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/technical-support-visit' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : tsVisitData
                    }

                    technicalSupportServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success").then(
                            this.$router.push({ path: '/technical-support-visit' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        deleteAttachmentClick(){
            $('#Attachment').val('');
            this.AttachmentStatus = '';
            this.Attachment = '';
        }
    }
}
</script>

<style scoped>
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>